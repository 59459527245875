import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { CenterTextDiv } from '../components/common'
import styled from '@emotion/styled'
import Button from '../components/button'

const PostImage = styled.div`
  height: 300px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  margin-bottom: 2rem;
`

const PostContainer = styled.div`
  text-align: center;
  margin: 4rem 0;

  & > a {
    color: black;
  }

  & p {
    font-size: 1rem;
    line-height: 1.5;
  }

  & h3 {
    font-weight: 700 !important;
  }
`

const PostText = styled.div`
  @media (min-width: 720px) {
    margin: 0 3rem;
  }
`

const NotFoundPage = ({ data }) => {
  const {
    allContentfulBlogArtikel: { edges },
  } = data

  return (
    <Layout>
      <SEO title="Blog | Yogisch Leben" />
      <CenterTextDiv>
        <h2>Yogisch Leben - Blog</h2>
        <p>
          Tauche ein in die Welt des Yoga und entdecke die Vielfalt an Themen,
          die das yogische Leben zu bieten hat. Hier findest Du wöchentlich eine
          neue Auswahl an verschiedene Praxis-Tipps undZusammenfassungen aus den
          Yogakursen, einen Einblick in die Yoga-Theorie und Inspirationenzum
          täglichen Leben. Wir wünschen Dir viel Freude beim Lesen und
          Entfalten.
        </p>
      </CenterTextDiv>

      {edges.map(({ node }) => {
        const linkTo = `/blog/${node.slug}`

        return (
          <PostContainer>
            <Link to={linkTo}>
              <PostImage>
                <Image
                  style={{ width: '100%' }}
                  fluid={node.featured_image.fluid}
                />
              </PostImage>
              <PostText>
                <h3>{node.title}</h3>
                <p>{node.content_preview.content_preview}</p>
              </PostText>
            </Link>
            <Button to={linkTo}>Zum Artikel</Button>
          </PostContainer>
        )
      })}
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  {
    allContentfulBlogArtikel(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          id

          title

          content_preview {
            content_preview
          }

          slug

          category {
            id
            name
          }

          featured_image {
            file {
              url
            }

            fluid(quality: 100, maxWidth: 720) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
